<!-- PrintjobInfo -->
<ion-item lines="none" *ngIf="showPrintJobInfo">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h3 class="modal-title text-center light">{{"Info" | translate}}</h3>
          </div>
          <div class="modal-body">

              <ul class="info-list">
                  <li class="row">
                      <div class="info-list__header">{{"Name" | translate}}</div>
                      <div class="info-list__content">{{printJob.jobName}}</div>
                  </li>
                  <li class="row" *ngIf="printJob.deletedTime">
                      <div class="info-list__header">{{"LastModified" | translate}}</div>
                      <div class="printer-features--lg info-list__content">
                          <span am-time-ago="printJob.deletedTime">{{printJob.deletedTime | amTimeAgo}}</span>
                      </div>
                  </li>
                  <li class="row">
                      <div class="info-list__header">{{"PPages" | translate}}</div>
                      <div class="printer-features--lg info-list__content">{{printJob.pageCount}}</div>
                  </li>
                  <li class="row">
                      <div class="info-list__header">{{"Created" | translate}}</div>
                      <div class="printer-features--lg info-list__content">
                          <span am-time-ago="printJob.info.submitTime">{{printJob.submitTime | amLocale: currentLanguage | amTimeAgo}}</span>
                      </div>
                  </li>
                  <li class="row" *ngIf="workstationName">
                      <div class="info-list__header">{{"StoredOnComputer" | translate}}</div>
                      <div class="printer-features--lg info-list__content">{{workstationName}}</div>
                  </li>
                  <li class="row" *ngIf="cloudStorage">
                      <div class="info-list__header">{{"CloudStorage" | translate}}</div>
                      <div class="printer-features--lg info-list__content"><i class="icon-ok"></i></div>
                  </li>
              </ul>
          </div>
          <div class="modal-footer">
              <button (click)="dismiss()">
                  {{"Close" | translate}}
              </button>
          </div>
      </div>
  </div>
</ion-item>

<!--  PrinterInfo  -->
<ion-item *ngIf="showPrinterInfo">
  <div class="modal-dialog show-printer-info">
      <div class="modal-content">
          <div class="modal-header">
              <!-- <ion-button class="close" slot="end" (click)="dismiss()"><i class="icon-close"></i></ion-button> -->
              <h3 class="modal-title">{{"Info" | translate}}</h3>
          </div>
          <div class="modal-body">

              <ul class="info-list">

                  <!-- ID -->
                  <li class="row">
                      <div class="info-list__header">{{"ID" | translate}}</div>
                      <div class="info-list__content">{{printer.signId}}</div>
                  </li>

                  <!-- Name -->
                  <li class="row" *ngIf="printer.name">
                      <div class="info-list__header">{{"Name" | translate}}</div>
                      <div class="info-list__content">{{printer.name}}</div>
                  </li>

                  <!-- status -->
                  <div *ngIf="printer.type === 'FREEDOM'">
                      <li class="row">
                          <div class="info-list__header">{{"Status" | translate}}</div>
                          <div class="info-list__content">
                              <div class="printer-status status-placement printer-status--freedom mr10"></div>
                              <span class="status-text-placement">{{"Ready" | translate}}</span>
                          </div>
                      </li>
                  </div>
                  <div *ngIf="printer.type !== 'FREEDOM'">
                      <li class="row">
                          <div class="info-list__header">{{"Status" | translate}}</div>
                          <div class="info-list__content">
                              <!-- status dots, only for DEVICE_NOTFOUND & 'Ready' -->
                              <span class="printer-status ml10"
                              [ngClass]="
                              {'printer-status--online': printer.embedded.status.state === 'READY',
                              'printer-status--warning': printer.embedded.status.state === 'WARNING',
                              'printer-status--offline': printer.embedded.status.state === 'ERROR',
                              'printer-status--undefined': printer.embedded.status.state === 'UNAVAILABLE'}">
                              </span>
                              <!-- <span class="printer-status ml10"
                              [ngClass]="{'printer-status--online': !printerHasWarnings(printer) && !printerHasErrors(printer) && printerHasProxy(printer) && !printerNotAvailable(printer),
                                          'printer-status--offline': printerHasProxy(printer) && printerNotAvailable(printer),
                                          'printer-status--undefined': !printerHasProxy(printer)}">
                              </span> -->
                              <!-- printer ready -->
                              <span class="status-text-placement" *ngIf="printer.embedded.status.state === 'READY'">{{'Ready' | translate}}</span>
                              <!-- <span class="status-text-placement" *ngIf="!printerHasErrors(printer) && !printerHasWarnings(printer) && !printerNotAvailable(printer)">{{'Ready' | translate}}</span> -->
                              <!-- Poll status DEVICE_NOT_FOUND -->
                              <span class="printer-status__label" *ngIf="printer.embedded.status.state === 'UNAVAILABLE'">{{'OFFLINE' | translate}}</span>
                              <!-- <span class="printer-status__label" *ngIf="printerNotAvailable(printer)">{{'Offline' | translate}}</span> -->
                              <!-- loader dots -->
                              <span class="loader loader--sm loader--compact col-xs-4 loader--spaceless" *ngIf="gettingPrinter">
                                <span></span>
                              </span>
                              <!-- printer error -->
                              <div *ngIf="printer.embedded.status.state === 'ERROR'">
                                  <div class="text-error" *ngFor="let error of printer.embedded.status.errors">
                                      <span class="printer-status status-placement"
                                      [ngClass]="{'printer-status--offline': printer.embedded.status.state === 'ERROR',
                                                  'printer-status--undefined': printer.embedded.status.state === 'UNAVAILABLE'}">
                                      </span>
                                      <span class="printer-status__label">{{error | translate}}</span>
                                  </div>
                              </div>
                              <!-- printer warning -->
                              <div *ngIf="printer.embedded.status.state === 'WARNING' && printer.embedded.status.state !== 'UNAVAILABLE'">
                                  <div class="text-warning" *ngFor="let warning of printer.embedded.status.warnings">
                                      <span class="printer-status status-placement"
                                      [ngClass]="{'printer-status--warning': printer.embedded.status.state === 'WARNING',
                                                  'printer-status--undefined': printer.embedded.status.state === 'UNAVAILABLE'}">
                                      </span>
                                      <span class="printer-status__label">{{warning | translate}}</span>
                                  </div>
                              </div>
                          </div>
                      </li>
                  </div>

                  <!-- Model -->
                  <li class="row" *ngIf="printer.embedded.printerModel && printer.embedded.printerModel.modelName">
                      <div class="info-list__header">{{"Model" | translate}}</div>
                      <div class="info-list__content">{{printer.embedded.printerModel.modelName}}</div>
                  </li>

                  <!-- Location -->
                  <li class="row" *ngIf="printer.location">
                      <div class="info-list__header">{{"Location" | translate}}</div>
                      <div class="info-list__content">{{printer.location}}</div>
                  </li>

                  <!-- Features -->
                  <li class="row" *ngIf="(printer.colorSupport || printer.duplexSupport || (printer.pdls.indexOf('PDF') > -1)) && printer.type !== 'FREEDOM'">
                      <div class="info-list__header">{{"Features" | translate}}</div>
                      <div class="info-list__content gap">
                          <div class="" *ngIf="printer.colorSupport">
                              <img class="printer-feature__icon printer-feature--color" title="Farve" ng-src="https://assets.printix.net/img/v2/icons/printer-features/cmyk.svg" src="https://assets.printix.net/img/v2/icons/printer-features/cmyk.svg">
                              <span class="printer-feature__label ng-binding">
                                  {{"SupportColor" | translate}}
                              </span>
                          </div>
                          <div class="" *ngIf="printer.duplexSupport">
                              <img class="printer-feature__icon printer-feature--duplex" title="2-sidet" ng-src="https://assets.printix.net/img/v2/icons/printer-features/duplex.svg" src="https://assets.printix.net/img/v2/icons/printer-features/duplex.svg">
                              {{"SupportDuplex" | translate}}
                          </div>
                          <div class="" *ngIf="(printer.pdls.indexOf('PDF') > -1)">PDF</div>
                      </div>
                  </li>
              </ul>
          </div>
          <div class="modal-footer">
              <button (click)="dismiss()">
                  {{"Close" | translate}}
              </button>
          </div>
      </div>
  </div>
</ion-item>

<!-- Select printQueue -->
<ion-item *ngIf="selectPrintQueue">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <div class="select-printer-clickzone">
                  <div class="printer-id printer-id--online pull-left"
                      [ngClass]="{'adjust-signId' : printer.duplexSupport || printer.colorSupport}">
                      <span class="sign-id">{{printer.signId}}</span>
                      <div class="printer-features" ng-show="printer.duplexSupport || printer.colorSupport">
                          <img class="printer-feature__icon printer-feature--color"
                          title="{{'Color print' | translate}}"
                          *ngIf="printer.colorSupport"
                          src="https://assets.printix.net/img/v2/icons/printer-features/cmyk.svg"
                          alt="CMYK icon">

                          <img class="printer-feature__icon printer-feature--duplex"
                          title="{{'Two-sided print' | translate}}"
                          *ngIf="printer.duplexSupport"
                          src="https://assets.printix.net/img/v2/icons/printer-features/duplex.svg"
                          alt="Duplex icon">
                      </div>
                  </div>
                  <!-- Printer queue modal -->
                  <span class="printer-in-popover pull-left">
                      <div class="select-queue-printer-name">{{printer.name}}</div>
                      <div class="printer-status-container">
                          <!-- printer status dots -->
                          <span class="printer-status ml10"
                          [ngClass]="{
                          'printer-status--online': printer.embedded.status.state === 'READY',
                          'printer-status--warning': printer.embedded.status.state === 'WARNING',
                          'printer-status--offline': printer.embedded.status.state === 'ERROR',
                          'printer-status--undefined': printer.embedded.status.state === 'UNAVAILABLE'}">
                          </span>
                          <!-- <span class="printer-status ml10"
                          [ngClass]="{'printer-status--online': !printerHasWarnings(printer) && !printerHasErrors(printer) && printerHasProxy(printer) && !printerNotAvailable(printer),
                                      'printer-status--warning': printerHasWarnings(printer) && !printerHasErrors(printer) && printerHasProxy(printer) && !printerNotAvailable(printer),
                                      'printer-status--offline': (printerHasErrors(printer) && printerHasProxy(printer)) || printerNotAvailable(printer),
                                      'printer-status--undefined': !printerHasProxy(printer)}">
                          </span> -->
                          <span *ngIf="!printer.embedded.status.pollStatus">
                              <span class="status-text-placement" *ngIf="printer.embedded.status.state !== 'ERROR'">
                                  {{'ONLINE' | translate}}
                              </span>
                              <span class="status-text-placement" *ngIf="printer.embedded.status.state === 'ERROR'">
                                  {{'ERROR' | translate}}
                              </span>
                              <span class="loader loader--sm loader--compact col-xs-4 loader--spaceless" *ngIf="gettingPrinter">
                                  <span></span>
                              </span>
                          </span>
                          <span class="" *ngIf="printer.embedded.status.pollStatus">
                              <span>
                                  <span class="status-text-placement" *ngIf="printer.embedded.status.state === 'READY'">{{'Ready' | translate}}</span>
                                  <span class="status-text-placement" *ngIf="printer.embedded.status.state === 'WARNING'">{{'WARNING' | translate}}</span>
                                  <span class="status-text-placement" *ngIf="printer.embedded.status.state === 'ERROR'">{{'ERROR' | translate}}</span>
                                  <span class="status-text-placement" *ngIf="printer.embedded.status.state === 'UNAVAILABLE'">{{'OFFLINE' | translate}}</span>
                              </span>
                              <!-- <span>
                                  <span class="status-text-placement" *ngIf="!printerHasErrors(printer) && !printerHasWarnings(printer) && !printerNotAvailable(printer)">{{'Ready' | translate}}</span>
                                  <span class="status-text-placement" *ngIf="!printerHasErrors(printer) && printerHasWarnings(printer) && !printerNotAvailable(printer)">{{'WARNING' | translate}}</span>
                                  <span class="status-text-placement" *ngIf="printerHasErrors(printer) || printerNotAvailable(printer)">{{'ERROR' | translate}}</span>
                              </span> -->
                          </span>
                      </div>
                      <div *ngIf="printer.location" class="printer-location weight300 size90">{{printer.location}}</div>
                  </span>
              </div>
              <ion-button class="close" (click)="dismiss('cancelRelease')"><i class="icon-close size80"></i></ion-button>
          </div>
          <div *ngIf="!printerAccessError">
              <div class="modal-body print-queue-list">
                  <div class="sub-title weigth400">
                      <button class="showJobs-btn" (click)="showJobs = !showJobs">

                          {{printer.printJobs.length}}
                          <span *ngIf="printer.printJobs.length > 1">{{'Docs' | translate}}</span>
                          <span *ngIf="printer.printJobs.length === 1">{{'Doc' | translate}}</span>
                          <i class="icon-angle-down"></i>
                      </button>

                      <div *ngIf="showJobs" class="printJobsList">
                          <div *ngFor="let printJob of printer.printJobs">
                              <p class="printjob-subtitle">
                                  {{printJob.jobName}}
                              </p>
                          </div>
                      </div>
                  </div>

                  <!-- <div class="unavailable-printer" *ngIf="printer.type !== 'FREEDOM' && (!printerHasProxy(printer) || printerNotAvailable(printer))">{{'PrinterIsUnavailable' | translate }}</div> -->

                  <div *ngIf="printer.embedded.status.state === 'UNAVAILABLE'">
                      <div class="no-print-queue" *ngIf="printer.queues.length === 0">
                          <p>{{"NoPrintQueue" | translate}}</p>
                      </div>
                  </div>

                  <div class="text-left queue-row" *ngFor="let queue of printer.queues" >

                      <div class="name">
                          {{queue.name}}
                      </div>

                      <button class="btn btn-primary action-btn" (click)="print(queue)">
                          <i class="icon-print-solid size120"></i>
                      </button>

                  </div>
              </div> <!-- End of modal body -->

          </div>
          <!-- Error Modal -->
          <div *ngIf="printerAccessError || printer.embedded.status.state === 'UNAVAILABLE'">
              <div class="modal-body no-printer-access">
                  <ul class="info-list">
                      <li class="row">
                          <div class="info-list__header">{{"PrinterUnavailable" | translate | stringular: printer.name}}</div>
                      </li>
                  </ul>
              </div>
              <div class="modal-footer">
                  <button class="btn btn-primary" (click)="dismiss('cancelRelease')">
                      {{"Close" | translate}}
                  </button>
              </div>
          </div>
      </div>
  </div>
</ion-item>

<!-- Networks List -->
<ion-list *ngIf="showNetworksList">
  <ion-label class="networks-list-title">{{"Networks" | translate}}</ion-label>
  <ion-item lines="none">
    <ion-searchbar
    placeholder="{{'Search' | translate}}"
    [(ngModel)]="searchInput"
    showClearButton="focus"
    clear-icon
    (change)="onSearch($event, searchInput)"
    (ionClear)="onSearch($event, null)">
    </ion-searchbar>
  </ion-item>
  <div class="network-list-container">
      <ion-item *ngFor="let network of tenantNetworks">
          <ion-label class="network-name">{{network.name}}</ion-label>
          <ion-checkbox class="network-checkbox" [(ngModel)]="network.checked" (ionChange)="toggleNetwork(network)">{{network.name}}</ion-checkbox>
      </ion-item>
      <ion-item *ngIf="tenantNetworks.length === 0">
        <ion-label class="text-center">{{"NoNetworkMatch" | translate}}</ion-label>
      </ion-item>
  </div>
  <div class="button-container">
    <ion-button (click)="dismiss()">{{'Close' | translate}}</ion-button>
    <!-- <button ion-button class="btn" (click)="updateFiltersToDB()">{{'Ok' | translate}}</button> -->
</div>
</ion-list>
<ion-list class="networks-list-buttons" *ngIf="showNetworksList && pagingObject && pagingObject.totalPages > 1">
<!-- <ion-list class="networks-list-buttons"> -->
    <div class="pagination-container">
        <button class="btn btn-sm prev" (click)="currentNetworksPage > 0 ? getNextPage(true) : false" [disabled]="pagingObject.number === 0">
            <div class="icon-left-open"></div>
        </button>
        <span class="paging-numerics">{{pagingObject.number + 1}}/{{pagingObject.totalPages}}</span>
        <button class="btn btn-sm next" [disabled]="currentNetworksPage + 1 >= pagingObject.totalPages" (click)="getNextPage()">
            <i class="icon-right-open"></i>
        </button>
    </div>
</ion-list>

<!-- show error modal -->
<!-- <ion-content *ngIf="showErrorModal">
  <h1 class="text-center mb-20 mt50">
  </h1>
  <h3 class="text-center p30">{{"UpsSomethingWentWrong" | translate}}</h3>
  <div class="text-center try-button">

    <ion-button (click)="tryAgain()">{{'TryAgain' | translate}}</ion-button>
  </div>
</ion-content> -->
