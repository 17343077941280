import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { Observable, Subject, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, takeUntil, tap } from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';
import { ErrorService } from '../error/error.service';
import { TenantService } from '../tenant/tenant.service';
import { IUser } from '../user/models/user.model';
import { UserService } from '../user/user.service';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Platform } from '@ionic/angular';
import { Broadcaster } from '../events/broadcaster.class';

@Injectable({
  providedIn: 'root'
})
export class SendLogsService {

  private allLogs: string = null;
  private arrayOfLogs: Array<string> = [];
  private currentUser: IUser = null;
  private deviceLogsUploadUrl: string = null;
  private logger: Logger;
  private logUploadHeaders: {} = null;
  private logUploadResourceUrl: string = null;
  private logUploadUrl: string = null;
  // private EMAIL_FOR_LOGS: string = 'gambarle@gmail.com';
  private EMAIL_FOR_LOGS: string = 'support+id4710@printix.zendesk.com';
  private SUPPORT_TICKET_ID: number =  null;
  private unSubscribe: Subject<boolean> = new Subject<boolean>();
  private httpErrorResponse: HttpErrorResponse;

  constructor (
    private broadcaster: Broadcaster,
    private dialogService: DialogService,
    private emailComposer: EmailComposer,
    private errorService: ErrorService,
    private file: File,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    private platform: Platform,
    private tenantService: TenantService,
    private userService: UserService
  )
  {
    this.logger = loggingService.getLogger("[SendLogsService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  private getLogsOnDevice(): Observable<any> {
    this.logger.info('getLogsOnDevice()');
    return new Observable<any>((observer: Subscriber<any>) => {
      let logs = this.loggingService.getLogMessagesFromLocalStorage('deviceLogs');

      observer.next(logs);
      observer.complete();
    });
  }

  public handleLogsForEmail() {
    this.logger.info('handleLogsForEmail()');

    this.getLogsOnDevice().subscribe((entries: any) => {

      this.createFileForLogs(entries);

      }, (getLogsError) => {
        this.logger.info('getLogsError: ' + JSON.stringify(getLogsError));
      });
  }

  private createFileForLogs(entries) {
    this.logger.info('createFileForLogs()');

    const logArray = this.mapEntriesToArray(entries);
    const logText = logArray.join('\r\n');

    this.broadcaster.broadcast('DISABLE_RESUME');

    this.file.createFile(this.file.dataDirectory, 'logs', true).then((fileCreatedData) => {
      let logTextBlob = new Blob([logText], { type: 'plain/text'});
      this.file.writeFile(this.file.dataDirectory, 'logs', logTextBlob, {replace: true, append: false}).then((fileWrittenData) => {
        if (this.platform.is('android')) {
          this.emailComposer.hasAccount().then((hasAccount) => {
            this.openEmailClient(fileWrittenData["nativeURL"]);
          });
        } else {
          this.openEmailClient(fileWrittenData["nativeURL"]);
        }
      });
    }, (error) => {
      this.broadcaster.broadcast('ENABLE_RESUME');
      this.logger.error('Error on creating logs-file for email: ' + JSON.stringify(error));
    });
  }

  private mapEntriesToArray(entries) {
    this.logger.info('mapEntriesToArray()');
    return entries.map(item => {
      const timeStamp = item['timeStamp'].toString();
      const dateTime = timeStamp.split('(')[0];
      const log = dateTime + item['logger'] + item['methodName'];
      return log;
    });
  }

  private openEmailClient(logsURL) {
    this.logger.info('openEmailClient()');
    this.currentUser = this.userService.user;
    let subject = this.currentUser ? 'Logs from Printix App, User: ' + this.currentUser.name : 'Logs from Printix App';

    let email = {
      to: '',
      cc: '',
      bcc: '',
      attachments: logsURL,
      subject: subject,
      body: '',
      isHtml: false
    };

    this.emailComposer.open(email).then((res) => {
      // Consider showing modal for email sendt! but we don't know if the emial was sendt or cancelled
      this.file.removeFile(this.file.dataDirectory, 'logs');
      this.broadcaster.broadcast('ENABLE_RESUME');
    });
  }

  public handleLogsUploadToServer(supportID: number) {
    this.logger.info('handleLogsUploadToServer()');
    this.SUPPORT_TICKET_ID = supportID;
    this.deviceLogsUploadUrl = this.tenantService.tenant.links.deviceLogUploads;
    this.dialogService.showLoadingSpinnerDialog('handleLogsUploadToServer()').subscribe(() => {
      this.createResourceForLogsOnServer()
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((logsResource) => {
        this.logUploadUrl = logsResource._links["px:logUploadUrl"]["href"];
        this.logUploadHeaders = logsResource["uploadHeaders"];
        this.logUploadResourceUrl = logsResource._links["self"]["href"];
        this.unSubscribe.next();
        this.unSubscribe.complete();

        this.getLogsOnDevice().subscribe((entries: any) => {

          let logArray = entries.map(item => {
            const timeStamp = item['timeStamp'].toString();
            const dateTime = timeStamp.split('(')[0];
            const log = dateTime + item['logger'] + item['methodName'];
            return log;
          });

          let logText = logArray.join('\r\n');

          this.uploadLogsToServer(logText).subscribe(() => {
            this.dialogService.hideLoadingSpinnerDialog('SendLogsService - uploadLogsToServer()').then(() => {
              this.dialogService.showTranslatedMessage('LogUploadSucces', this.SUPPORT_TICKET_ID.toString());
            });
          });
        });
      });
    });
  }

  private createResourceForLogsOnServer(): Observable<any> {
    this.logger.info('createResourceForLogsOnServer()');
    return new Observable((observer) => {
      this.httpClient.post<any>(this.deviceLogsUploadUrl, {"zendeskTicketId": this.SUPPORT_TICKET_ID})
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          this.dialogService.hideLoadingSpinnerDialog('handleLogsUploadToServer() - ERROR createResourceForLogsOnServer()');
          observer.complete();
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('createResourceForLogsOnServer() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else if (this.httpErrorResponse.status === 400) {
            this.logger.info('createResourceForLogsOnServer() httpErrorResponse === 400');
            this.dialogService.showTranslatedMessage('Bad request: ' + this.httpErrorResponse['error']['errorText'] + ' ' + this.SUPPORT_TICKET_ID);
          } else if (this.httpErrorResponse.status === 404) {
            this.logger.info('createResourceForLogsOnServer() httpErrorResponse === 404');
            this.dialogService.showTranslatedMessage('SupportRequestDoesNotExist', this.SUPPORT_TICKET_ID);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[SendLogsService] createResourceForLogsOnServer()');
          }
        })
      )))
      .subscribe((deviceLog: object) => {
        observer.next(deviceLog);
        observer.complete();
      });
    });
  }

   private uploadLogsToServer(logs: any): Observable<any> {
    this.logger.info('uploadLogsToServer()');

      return new Observable((observer) => {
        this.httpClient.put<any>(this.logUploadUrl, logs, {headers: this.logUploadHeaders})
        .pipe(retryWhen(error => error.pipe(
          delay(1000),
          take(3),
          // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
          tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
          finalize(() => {
            this.dialogService.hideLoadingSpinnerDialog('handleLogsUploadToServer() - ERROR uploadLogsToServer()');
            if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
              this.logger.info('uploadLogsToServer() httpErrorResponse === ' + this.httpErrorResponse.status);
            } else {
              this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PUT', '[sendLogsService] uploadLogsToServer()');
            }
            observer.error(this.httpErrorResponse);
            observer.complete();
          })
        )))
        .subscribe((response) => {
          this.confirmUpload()
          .pipe(takeUntil(this.unSubscribe))
          .subscribe((response) => {
            this.unSubscribe.next();
            this.unSubscribe.complete();
            observer.next(response);
            observer.complete();
          });
        });
      });
    }

  private confirmUpload(): Observable<any> {
    this.logger.info('confirmUpload()');
    let objectToPatch: any = [
      {
        "op": "replace",
        "path": "/state",
        "value": "FINISHED_UPLOADING"
      }
    ];
    return new Observable((observer) => {
      this.httpClient.patch<any>(this.logUploadResourceUrl, objectToPatch)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          this.dialogService.hideLoadingSpinnerDialog('uploadLogsToServer() - ERROR uploadLogsToServer()');
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('confirmUpload() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.logger.info('confirmUpload() PATCH ERROR - Object to send: ' + JSON.stringify(objectToPatch));
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[sendLogsService] confirmUpload()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }
}