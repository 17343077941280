import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { ILanguageItem } from 'src/app/services/language/models/language.model';
import { ITenantLite } from 'src/app/services/tenant/models/tenant-lite.model';
import { IMenuItem } from './menu-item.model';
import { MenuItems } from './menu-items';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { PopoverComponent } from '../popover/popover.component';
import { NetworkCheckPage } from 'src/app/pages/auth/network-check/network-check.page';
import { PopoverController, Platform, NavController, MenuController } from '@ionic/angular';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { ReleaseResourceService } from 'src/app/services/release-resource/release-resource.service';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { SendLogsService } from 'src/app/services/logs/send-logs.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { UserService } from 'src/app/services/user/user.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { PrintJobsPage } from 'src/app/pages/print-jobs/print-jobs.page';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Device } from '@ionic-native/device/ngx';
import { IUser } from 'src/app/services/user/models/user.model';
import { NetworkService } from 'src/app/services/network/network.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {

  @Input() content: any;

  public appVersion = '3.0.7';
  private currentUrl: string;
  private isCordova: boolean = this.platformService.isCordova;
  public languageItems: Array<ILanguageItem> = [];
  private logger: Logger;
  public menuItems: Array<IMenuItem> = _.cloneDeep(MenuItems);
  private securePrintLevel: string = null;
  private pagesToPushToNavigationStack: Array<string> = ['Printers', 'RegisterNfcTag', 'History'];
  public showLanguageMenu: boolean = false;
  public isInternetAccessAvailable = true;
  private systemManager: string = 'SYSTEM_MANAGER';
  public tenantList: Array<ITenantLite> = null;
  private unSubscribe: Subject<boolean> = new Subject<boolean>();
  public user: IUser = null;
  private printJobsPageType: string = 'printJobsPage';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private broadcaster: Broadcaster,
    private device: Device,
    private dialogService: DialogService,
    private file: File,
    private inAppBrowser: InAppBrowser,
    private languageService: LanguageService,
    private loggingService: LoggingService,
    private menuController: MenuController,
    private navCtrl: NavController,
    private networkService: NetworkService,
    private platform: Platform,
    private platformService: PlatformService,
    public popoverCtrl: PopoverController,
    private releaseResourceService: ReleaseResourceService,
    private safariViewController: SafariViewController,
    private sendLogsService: SendLogsService,
    private storageService: StorageService,
    private tenantService: TenantService,
    private userService: UserService,
    // private zip: Zip
  ) {
    this.logger = loggingService.getLogger("[MenuComponent]");
    const methodName = "ctor";
    this.logger.entry(methodName);
    this.logger.info(methodName + ' APP_VERSION: ' + this.appVersion)


    broadcaster.on('securePrintLevel').subscribe((securePrintLevel: string) => {
      this.securePrintLevel = securePrintLevel;
    });

    broadcaster.on('printJobsPageType').subscribe((printJobsPageType: string) => {
      this.printJobsPageType = printJobsPageType;
    });

    this.networkService.isInternetAvailable
    .subscribe((isInternetAccessAvailable: boolean) => {
      this.isInternetAccessAvailable = isInternetAccessAvailable;
    });
  }

  public menuOnClose(): void {
    this.showLanguageMenu = false;
  }

  public showMenuItem(menuItem: IMenuItem): boolean {

    if (!this.isInternetAccessAvailable) {
      return false;
    }

    if (menuItem.languageMenuToggle) {
      return true;
    }

    if (menuItem.title === 'SignIn' && this.tenantService.tenantListAuthenticated) {
      return false;
    }

    if (menuItem.title === 'SignOut' && this.tenantService.tenantListAuthenticated) {
      return true;
    }


    if ((menuItem.showIfAuthenticated && this.user !== null) || (menuItem.showIfNotAuthenticated && this.user === null)) {
      if (this.router.url.includes('network-check')) {
        return false
      } else {
        if (menuItem.title === 'AirPrintPassword') {
          let currentTenant: any = this.tenantService.tenant;
          if (!this.platform.is('ios') && !this.platform.is('ipad')) {
            return false;
          } else if (!this.isCordova) {
            return false;
          } else if (!currentTenant.tenantData.mobilePrint) {
            return false;
          }
        }

        if (menuItem.title === 'PrinterIdMethod') {
          if (this.isCordova) {
            return true;
          } else {
            return false;
          }
        }

        if (menuItem.title === 'RegisterNfcTag') {
          if (this.user.embedded.permissions.role === this.systemManager) {
            if (this.platform.is('android')) {
              return true;
            } else if (this.platform.is('iphone')) {
              let deviceVersionNumber = parseInt(this.device.version);
              if (deviceVersionNumber > 12) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        }

        if (menuItem.title === 'Diagnostics') {
          if (this.isCordova) {
            return true;
          } else {
            return false;
          }
        }

        if (this.tenantList && this.tenantList.length === 1 && menuItem.title === 'ChangeHome') { // Dont show ChangeHome menuItem if there is only one tenant.
          return false;
        }

        if (menuItem.title === 'Capture' && !this.platform.is("hybrid")) {
          return false;
        }

        return true;
      }
    }
  }

  public async menuItemClick(event, menuItem: IMenuItem): Promise<void> {
    this.logger.info('(click) ' + menuItem.title)
    if (menuItem.title === 'SignIn') {
      let urlTree = this.router.parseUrl(this.router.url);
      urlTree.queryParams = {}
      let currentUrl = urlTree.toString();

      if (menuItem.route === currentUrl) {
        location.reload();
      }
    }

    if (menuItem.title === 'Help') {
      let url: string = 'https://docshield.tungstenautomation.com/Printix/en_US/help/user/';
      if (this.isCordova) {
        this.safariViewController.isAvailable()
        .then((available: boolean) => {
          if (available) {
            this.safariViewController.show({
              url: url
            })
            .pipe(takeUntil(this.unSubscribe))
            .subscribe();
          } else {
            // use fallback browser,
            this.inAppBrowser.create(url, '_system');
          }
        });
      } else {
        this.logger.info('open manual');
        this.inAppBrowser.create(url);
        // window.open(url, '_system', 'location=yes');
      }
    }

    if (menuItem.title === 'AirPrintPassword') {
      let dataPackage: any = {
        type: 'airPrintPassword',
      };

      const popover = await this.popoverCtrl.create({
        component: PopoverComponent,
        componentProps: { dataPackage },
        cssClass: 'showAirPrintPasswordsList'
      });

      await popover.present();

      await popover.onDidDismiss()
        .then(popoverData => {
          if (popoverData.data === 'airPrintPassword') {

          }
        });

    }

    if (menuItem.title === 'PrinterIdMethod') {
      let dataPackage: any = {
        type: 'securePrintMethods',
        securePrintLevel: this.securePrintLevel
      };

      let popover = await this.popoverCtrl.create({
        component: PopoverComponent,
        componentProps: { dataPackage },
        cssClass: 'secure-print-method'
      });

      await popover.present();

      await popover.onDidDismiss()
        .then(() => {
          let currentUrl = this.getrCurrentUrl();
          if (currentUrl === '/print-jobs' || currentUrl === '/recents') {
            this.broadcaster.broadcast('reload_page');
          }
        });
    }

    if (menuItem.component) {
      if (menuItem.route === '/print-jobs') {
        this.navCtrl.navigateBack(menuItem.route);
      } else if (menuItem.route === '/capture') {
        this.navCtrl.navigateRoot(['/capture']);
      } else {
        if (this.pagesToPushToNavigationStack.indexOf(menuItem.title) !== -1) {
          this.navCtrl.navigateForward([menuItem.route], { queryParams: { resource: this.releaseResourceService.releaseResource } });
        } else {
          this.navCtrl.navigateForward([menuItem.route], { queryParams: menuItem.navParams });
        }
      }
    }

    if (menuItem.languageMenuToggle) {
      this.showLanguageMenu = !this.showLanguageMenu;
    }

    if (menuItem.title === 'Diagnostics') {
      let dataPackage: any = {
        type: 'sendLogs'
      };

      const popover = await this.popoverCtrl.create({
        component: PopoverComponent,
        componentProps: { dataPackage },
        cssClass: 'send-logs'
      });

      await popover.present();

      await popover.onDidDismiss()
        .then((sendLogsParameters) => {
          if (sendLogsParameters && sendLogsParameters.data !== undefined && sendLogsParameters.data !== null) {
            switch (sendLogsParameters.data['method']) {
              case 'SendLogsWithEmail':
                this.sendLogsService.handleLogsForEmail();
                break;
              case 'SendLogsToServer':
                this.sendLogsService.handleLogsUploadToServer(sendLogsParameters.data.ID);
                break;
            }
          }
        });

    }

    if (menuItem.title !== 'Language') {
      this.menuController.close();
    }
  }

  public languageItemClick(languageItem: ILanguageItem): void {
    this.languageService.changeLanguage(languageItem.codeLanguage);
    if (this.user) {
      this.user.embedded.user.language = languageItem.codeLanguage;
      this.userService.saveLanguageChange(languageItem.codeLanguage, this.user).subscribe(() => { });
    }
  }

  public isComponentActive(menuItem: IMenuItem): boolean {

    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {}
    let currentUrl = urlTree.toString();

    let showComponentAsActive = currentUrl === menuItem.route ? true : false;

    return showComponentAsActive;
  }

  private getrCurrentUrl(): string {
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {}
    let currentUrl = urlTree.toString();
    return currentUrl
  }

  public isLanguageActive(languageItem: ILanguageItem): boolean {
    return this.languageService.getCurrentLanguageCode() === languageItem.codeLanguage;
  }

  private setListeners(): void {
    this.broadcaster.on<any>('tenantList').subscribe(data => {
      this.tenantList = data;
    });

    this.broadcaster.on<any>('refreshedUser').subscribe(user => {
      this.user = user;
    });

    this.broadcaster.on<any>('loggedOutUser').subscribe(() => {
      this.user = null;
    });
  }

  public checkForMultipleTenants(): void {
    this.storageService.getItemFromKeyValueTable('numberOfTenants', 'menuComponent-checkForMultipleTenants')
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((data) => {
        this.unSubscribe.next();
        this.unSubscribe.complete();
      });
  }

  ngOnInit(): void {
    this.platform.ready().then(() => {
      this.logger.info('ngOnInit()');
      this.languageService.getLanguages()
        .subscribe((languageItems: Array<ILanguageItem>) => {
          this.languageItems = languageItems;
          this.setListeners();
        }, (httpErrorResponse: HttpErrorResponse) => {
          if (this.isCordova) {
            // this.dialogService.hideSplashScreen();
          }
        });
    });
  }

  ionViewWillLeave() {
  }
}
