import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';
import { PagingService } from '../paging/paging.service';
import { IPrintJob } from '../print-job/models/print-job.model';
import { IPrinterEmbedded } from '../printer/models/printer-embedded-model';
import { IPrinter } from '../printer/models/printer.model';
import { IQueue } from '../printer/models/queue.model';
import { TenantService } from '../tenant/tenant.service';
import { IUser } from '../user/models/user.model';
import { UserService } from '../user/user.service';
import * as _ from 'lodash';
import { PrinterService } from '../printer/printer.service';
import { PrintJobService } from '../print-job/print-job.service';
import { IPrintJobReleaseResource } from './models/IPrintJobReleaseResource.model';
import { IPrintJobRelease } from './models/IPrintJobRelease.model';
import { IReleaseJob } from './models/IReleaseJob.model';
import { Logger, LoggingService } from 'ionic-logging-service';

@Injectable({
  providedIn: 'root'
})
export class ReleaseHistoryService {

  private PRINTJOB_RELEASE_START: string = 'PRINTJOB_RELEASE_START';
  private RELEASE_PRINT_JOBS: string = 'RELEASE_PRINT_JOBS';
  private PRINTER: string = 'PRINTER';
  private QUEUE: string = 'QUEUE';
  private STATE_RELEASED: string = 'RELEASED';
  private STATE_TIMEOUT: string = 'TIMEOUT';
  private STATE_FINISHED: string = 'FINISHED';
  private STATE_FAILED: string = 'FAILED';
  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;

  constructor(
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    private printerService: PrinterService,
    private printJobService: PrintJobService,
    private tenantService: TenantService,
  ){
    this.logger = loggingService.getLogger("[ReleaseHistoryService]");
      const methodName = "ctor";
      this.logger.entry(methodName);
  }

  public getPrintJobReleaseUrl(currentUserUrl: string): Observable<string> {
    this.logger.info('getPrintJobReleaseUrl()');
    return new Observable<string>((observer: Subscriber<string>) => {
      this.httpClient.get<string>(currentUserUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getPrintJobReleaseUrl() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[ReleaseHistoryService] getPrintJobReleaseUrl()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((tenantUser: any) => {
        // this.logger.info(JSON.stringify(tenantUser));
        let printJobReleaseUrl: string = tenantUser['_links']['px:printjobReleases']['href'];
        observer.next(printJobReleaseUrl);
        observer.complete();
      });
    });
  }

  public getReleaseHistory(printJobReleaseUrl: string, page: number, pageSize: number, sort?: string, direction?: string): Observable<object> {
    this.logger.info('getReleaseHistory()');
    printJobReleaseUrl += '?page=' + page + '&pageSize=' + pageSize;
    printJobReleaseUrl += '&embed=' + this.RELEASE_PRINT_JOBS + '&embed=' + this.PRINTER + '&embed=' + this.QUEUE/* + '&embed=' + this.PRINTJOB_RELEASE_START*/;
    printJobReleaseUrl += '&states=' + this.STATE_RELEASED + '&states=' + this.STATE_FAILED + '&states=' + this.STATE_TIMEOUT + '&states=' + this.STATE_FINISHED;
    sort ? printJobReleaseUrl += '&sort=' + sort : '';
    direction ? printJobReleaseUrl += '&direction=' + direction : '';
    return new Observable<object>((observer: Subscriber<object>) => {
      this.httpClient.get<object>(printJobReleaseUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getReleaseHistory() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[ReleaseHistoryService] getReleaseHistory()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((rawRelease: object) => {
        observer.next(this.deserializePrintJobReleaseResource(rawRelease));
        observer.complete();
      });
    });
  }

  private deserializePrintJobReleaseResource(rawRelease: object): IPrintJobReleaseResource {
    let printJobReleaseResource: IPrintJobReleaseResource = {
      printJobReleases: rawRelease['_embedded'] ? this.setPrintJobReleaseResourceArray(rawRelease['_embedded']) : null,
      links: {
        self: rawRelease['_links']['self'] ? rawRelease['_links']['self']['href'] : null,
      },
      page: rawRelease['page']
    };
    return printJobReleaseResource;
  }

  private setPrintJobReleaseResourceArray(resources: Array<object>): Array<IPrintJobRelease> {
    this.logger.info('setPrintJobReleaseResourceArray()');
    let printJobRelease: Array<IPrintJobRelease> = [];

    for (let resource of resources['px:printJobReleaseResources']) {
      printJobRelease.push(this.deserializePrintJobRelease(resource));
    }
    return printJobRelease;
  }

  private deserializePrintJobRelease(resource): IPrintJobRelease {
    this.logger.info('deserializePrintJobRelease()');
    let releaseJobResource: IPrintJobRelease = {
      created: resource['created'] ? resource['created'] : null,
      links: resource['_links'] ? {
        matchingQueues: resource['_links']['px:matchingQueues']['href'],
        startRelease: resource['_links']['px:startRelease']['href'],
        self: resource['_links']['self']['href']
      } : null,
      modified: resource['modified'],
      printerQueue: resource['printerQueue'] ? {
        errors: resource['printerQueue']['errors'],
        links: {
          printer: resource['printerQueue']['_links']['px:printer'],
          printerQueue: resource['printerQueue']['_links']['px:printerqueue'],
          self:resource['printerQueue']['_links']['self'],
        },
        printer: resource['printerQueue']['_embedded']['printer'] ? this.printerService.deserializePrinter(resource['printerQueue']['_embedded']['printer'], true) : null,
        queue: resource['printerQueue']['_embedded']['queue'] ? resource['printerQueue']['_embedded']['queue'] : null,
      } : null,
      releaseJobs: resource['_embedded'] && resource['_embedded']['releasejobs'] ? this.setReleaseJobsArray(resource['_embedded']['releasejobs']) : null,
      state: resource['state'],
      version: resource['version'] ? resource['version'] : null
    };
    return releaseJobResource;
  }

  private setReleaseJobsArray(jobs: Array<object>): Array<IReleaseJob> {
    this.logger.info('setReleaseJobsArray()');

    let releaseJobs: Array<IReleaseJob> = [];

    for (let releaseJob of jobs) {
      releaseJobs.push(this.deserializeReleaseJob(releaseJob));
    }
    return releaseJobs;
  }

  private deserializeReleaseJob(releaseJob: object): IReleaseJob {
    this.logger.info('deserializeReleaseJob()');
    let job: IReleaseJob = {
      jobId: releaseJob['_links'] && releaseJob['_links']['px:job'] ? this.formatJobId(releaseJob['_links']['px:job']['href']) : null,
      releaseJob: releaseJob['_embedded'] && releaseJob['_embedded']['job'] ? this.printJobService.deserializePrintJob(releaseJob['_embedded']['job'], true) : null,
      releaseJobStatus: releaseJob['_embedded'] && releaseJob['_embedded']['releaseJobStatus'] ? releaseJob['_embedded']['releaseJobStatus'] : null
    };
    return job;
  }

  private formatJobId(jobUrl: string): string {
    let jobId = jobUrl.split('printjobs/')[1];
    return jobId;
  }
}